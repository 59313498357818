import axios from '../../config/axios'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    currentMonth: {
      month: moment().format('MM-YYYY'),
      total: 0.0,
      userTotals: {}
    },
    amountByMonth: []
  },
  mutations: {
    setCurrentMonth (state, currentMonth) {
      state.currentMonth = currentMonth
    },
    setAmountByMonth (state, amountByMonth) {
      state.amountByMonth = amountByMonth
    }
  },
  actions: {
    async getForMonth ({ commit, rootState }, { filters, month }) {
      const response = await axios.post('statistics/month', {
        accountBookId: rootState.accountBooks.current._id,
        month: month,
        filters: filters
      })

      commit('setCurrentMonth', response.data)
    },
    async amountByMonth ({ commit, rootState }, filters) {
      const response = await axios.post('statistics/amount_by_month', {
        accountBookId: rootState.accountBooks.current._id,
        ...filters
      })

      commit('setAmountByMonth', response.data)
    }
  }
}
