import axios from '../../config/axios'

const name = 'transactions'

export default {
  namespaced: true,
  state: {
    items: [],
    allLoaded: false,
    limit: 10,
    isLoading: true
  },
  mutations: {
    setItems (state, items) {
      state.items = items
    },
    appendItems (state, items) {
      state.items.push.apply(state.items, items)
    },
    allLoaded (state) {
      state.allLoaded = true
    },
    isLoading (state, isLoading) {
      state.isLoading = isLoading
    }
  },
  actions: {
    async fetch ({ commit, state, rootState }, params) {
      commit('isLoading', true)

      params = params || {}

      params.limit = state.limit
      params.accountBookId = rootState.accountBooks.current._id

      const response = await axios.get(name, {
        params: params
      })

      commit('setItems', response.data)

      if (response.data.length === 0 || response.data.length < state.limit) {
        commit('allLoaded')
      }

      commit('isLoading', false)
    },
    async loadMore ({ commit, state, rootState }, params) {
      params.limit = state.limit
      params.accountBookId = rootState.accountBooks.current._id

      const response = await axios.get(name, {
        params: params
      })

      commit('appendItems', response.data)

      if (response.data.length === 0 || response.data.length < state.limit) {
        commit('allLoaded')
      }
    },
    create ({ commit, dispatch }, transaction) {
      axios.post(name, transaction).then(response => {
        dispatch('fetch')
      })
    },
    update ({ commit, dispatch }, transaction) {
      axios.patch(`${name}/${transaction._id}`, transaction).then(response => {
        dispatch('fetch')
      })
    },
    get ({ commit }, _id) {
      return axios.get(`${name}/${_id}`)
    },
    async delete ({ commit, dispatch }, _id) {
      await axios.delete(`${name}/${_id}`)

      dispatch('fetch')
    }
  }
}
