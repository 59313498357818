import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCurrencyInput from 'vue-currency-input'
import Vue2TouchEvents from 'vue2-touch-events'
import VueI18n from 'vue-i18n'
import i18n from './i18n'
import Buefy from 'buefy'

Vue.config.productionTip = false
store.commit('setUser', JSON.parse(localStorage.getItem('user')))

new Vue({
  router,
  store,
  render: h => h(App),
  i18n
}).$mount('#app')

Vue.use(require('vue-moment'))
Vue.use(VueCurrencyInput)
Vue.use(Vue2TouchEvents)
Vue.use(VueI18n)
Vue.use(Buefy)

require('@/assets/scss/main.scss')
