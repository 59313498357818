<template xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <div class="account-book-list">
    <h1 class="title">{{ $t('accountBooks.list.title') }}</h1>

    <template v-if="isLoading">
      <loader v-for="i in 3" :key="i" :height="4" />
    </template>

    <ul v-else-if="accountBooks.length">
      <li class="account-book" :ref="`account_book_box_${accountBook._id}`" v-for="accountBook in accountBooks" :key="accountBook._id" v-touch:swipe="toggleContextMenu(accountBook._id)">
        <router-link :to="`/${accountBook.slug}`">
          <div class="box">
            <p class="subtitle">
              {{ accountBook.name }}
              <small v-if="accountBook.users.length > 1" class="icon has-text-primary-light">
                <i class="fa fa-user-friends"></i>
              </small>
            </p>
          </div>

          <div class="box context-menu">
            <router-link :to="`/account-books/edit/${accountBook.slug}`" class="button is-info">
                <span class="icon">
                  <i class="fas fa-pen"></i>
                </span>
            </router-link>
            <button class="button is-danger" @click.prevent="remove(accountBook._id)">
                <span class="icon">
                  <i class="fas fa-trash"></i>
                </span>
            </button>
          </div>
        </router-link>
      </li>
    </ul>

    <empty-state v-else>
      {{ $t('accountBooks.emptyState') }}
    </empty-state>

    <call-to-button link="/account-books/new" icon="fa fa-plus">{{ $t('accountBooks.newButton') }}</call-to-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CallToButton from '../basic/CallToButton'
import EmptyState from '../basic/EmptyState'
import Loader from '../basic/Loader'

export default {
  name: 'account-book-list',
  components: { Loader, EmptyState, CallToButton },
  computed: {
    ...mapState({
      accountBooks: state => state.accountBooks.items,
      isLoading: state => state.accountBooks.isLoading
    })
  },
  created () {
    this.$store.dispatch('accountBooks/fetch')
  },
  methods: {
    toggleContextMenu (accountBookId) {
      return dir => {
        if (dir === 'left') {
          this.$refs[`account_book_box_${accountBookId}`][0].classList.add('show-context-menu')
        }

        if (dir === 'right') {
          this.$refs[`account_book_box_${accountBookId}`][0].classList.remove('show-context-menu')
        }
      }
    },
    remove (_id) {
      if (confirm('Are you sure, you want to delete this account book?')) {
        this.$store.dispatch('accountBooks/delete', _id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/scss/variables';

  .account-book-list {
    .box {
      margin-bottom: 1rem;
    }
  }

  .account-book {
    transition: transform 0.2s ease;

    p.subtitle {
      margin-bottom: 0.25em;
    }

    &.show-context-menu {
      transform: translateX(-6rem);

      .context-menu {
        right: -6rem;
      }
    }

    .context-menu {
      transition: right 0.2s ease;
      display: flex;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      justify-content: flex-end;
      padding: 0;

      .button {
        height: 100%;
        border-radius: 0;
        width: 3rem;

        &:last-child {
          border-radius: 0 6px 6px 0;
        }
      }
    }
  }

  .new-account-book {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    margin-top: 1rem;
    border-radius: 6px;

    .icon {
      font-size: 2rem;
      margin: 0 0 0.5rem !important;
    }
  }
</style>
