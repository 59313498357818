import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import guard from './guard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { guarded: false }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../components/auth/Login.vue'),
    meta: { guarded: false }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../components/auth/Register.vue'),
    meta: { guarded: false }
  },
  {
    path: '/edit-profile',
    name: 'EditProfile',
    component: () => import(/* webpackChunkName: "editProfile" */ '../components/users/EditProfile.vue')
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import(/* webpackChunkName: "changePassword" */ '../components/users/ChangePassword.vue')
  },
  {
    path: '/:accountBookSlug',
    name: 'AccountBook',
    component: () => import(/* webpackChunkName: "accountBook" */ '../components/accountBooks/AccountBook.vue'),
    children: [
      {
        path: '/',
        name: 'AccountBookOverview',
        component: () => import(/* webpackChunkName: "accountBookOverview" */ '../components/accountBooks/AccountBookOverview.vue')
      },
      {
        path: 'transactions',
        name: 'Transactions',
        component: () => import(/* webpackChunkName: "transactions" */ '../views/Transactions.vue')
      },
      {
        path: 'transactions/new',
        name: 'NewTransaction',
        component: () => import(/* webpackChunkName: "newTransaction" */ '../components/transactions/NewTransaction.vue')
      },
      {
        path: 'transactions/edit/:id',
        name: 'EditTransaction',
        component: () => import(/* webpackChunkName: "editTransaction" */ '../components/transactions/EditTransaction.vue')
      },
      {
        path: 'categories',
        name: 'Categories',
        component: () => import(/* webpackChunkName: "categories" */ '../views/Categories.vue')
      },
      {
        path: 'categories/new',
        name: 'NewCategory',
        component: () => import(/* webpackChunkName: "newCategory" */ '../components/categories/NewCategory.vue')
      },
      {
        path: 'invite',
        name: 'Invite',
        component: () => import(/* webpackChunkName: "invite" */ '../components/accountBooks/Invite.vue')
      }
    ]
  },
  {
    path: '/account-books/new',
    name: 'NewAccountBook',
    component: () => import(/* webpackChunkName: "newAccountBook" */ '../components/accountBooks/NewAccountBook.vue')
  },
  {
    path: '/account-books/edit/:slug',
    name: 'EditAccountBook',
    component: () => import(/* webpackChunkName: "editAccountBook" */ '../components/accountBooks/EditAccountBook.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(guard)

export default router
