import axios from '../../config/axios'

const name = 'account-books'

export default {
  namespaced: true,
  state: {
    items: [],
    current: null,
    isLoading: true
  },
  mutations: {
    setItems (state, items) {
      state.items = items
    },
    setCurrent (state, current) {
      state.current = current
    },
    isLoading (state, isLoading) {
      state.isLoading = isLoading
    }
  },
  actions: {
    async fetch ({ commit }) {
      commit('isLoading', true)

      const response = await axios.get(name)

      commit('setItems', response.data)
      commit('isLoading', false)
    },
    async create ({ commit, dispatch }, accountBook) {
      const response = await axios.post(name, accountBook)

      dispatch('fetch')

      return response.data
    },
    update ({ commit, dispatch }, accountBook) {
      axios.patch(`${name}/${accountBook._id}`, accountBook).then(response => {
        dispatch('fetch')
      })
    },
    async get ({ commit }, slug) {
      const response = await axios.get(`${name}/${slug}`)

      commit('setCurrent', response.data)

      return response
    },
    async delete ({ commit, dispatch }, _id) {
      await axios.delete(`${name}/${_id}`)

      dispatch('fetch')
    },
    async invite ({ dispatch, state }, email) {
      await axios.post(`${name}/invite`, { email: email }, {
        params: { accountBookId: state.current._id }
      })

      dispatch('fetch')
    }
  }
}
