<template>
  <div class="call-to-button">
    <router-link v-if="link" :to="link" class="button is-primary has-icons-left">
      <span v-if="icon" class="icon">
        <i :class="icon"></i>
      </span>
      <span><slot></slot></span>
    </router-link>
    <button v-else-if="action" class="button is-primary" @click="action">
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'call-to-button',
  props: {
    link: {
      type: String
    },
    action: {
      type: Function
    },
    icon: {
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/scss/variables';

  .call-to-button {
    position: fixed;
    bottom: 0;
    left: 0;
    background: $main-background;
    padding: 0.5rem;
    width: 100%;

    .button {
      width: 100%;
    }
  }
</style>
