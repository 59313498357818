import axios from '../../config/axios'

export default {
  namespaced: true,
  state: {
    items: []
  },
  mutations: {
    setItems (state, items) {
      state.items = items
    }
  },
  actions: {
    fetch ({ commit, rootState }) {
      axios.get('users', {
        params: {
          accountBookId: rootState.accountBooks.current._id
        }
      }).then(response => {
        commit('setItems', response.data)
      })
    }
  }
}
