import axios from 'axios'
import store from '../store'

const basePath = process.env.VUE_APP_API_PATH ? process.env.VUE_APP_API_PATH : ''

const instance = axios.create({
  baseURL: basePath + '/api/'
})

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    store.dispatch('logout')
  } else {
    return Promise.reject(error)
  }
})

export default instance
