<template>
  <div class="home">
    <account-book-list v-if="isLoggedIn()" />
    <div v-else>
      <h1 class="title">{{ $t('home.welcomeTitle') }}</h1>

      <p class="mb-5">{{ $t('home.introText') }}</p>

      <div class="is-flex buttons">
        <router-link to="/register" class="button is-flex-grow-1">{{ $t('common.register') }}</router-link>
        <router-link to="/login" class="button is-primary is-flex-grow-1">{{ $t('common.login') }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AccountBookList from '../components/accountBooks/AccountBookList'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: { AccountBookList },
  created () {
    this.$store.commit('accountBooks/setCurrent', null)
  },
  methods: {
    ...mapGetters([
      'isLoggedIn'
    ])
  }
}
</script>
