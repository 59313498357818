<template>
  <div class="alert-bar">
    <div :class="{notification: true, 'is-danger': isDanger, 'is-success': isSuccess}">
      <span class="icon">
        <i :class="iconClass"></i>
      </span>
      <span>{{ alertBar.text }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'alert-bar',
  data () {
    return {
      displayTime: 3000
    }
  },
  computed: {
    ...mapState({
      alertBar: state => state.alertBar
    }),
    isDanger () {
      return this.alertBar.type === 'danger'
    },
    isSuccess () {
      return this.alertBar.type === 'success'
    },
    iconClass () {
      if (this.alertBar.type === 'success') {
        return 'fas fa-check-circle'
      }

      if (this.alertBar.type === 'success') {
        return 'fas fa-times'
      }

      return 'fas fa-info-circle'
    }
  },
  mounted () {
    setTimeout(() => {
      this.$store.commit('hideAlertBar')
    }, this.displayTime)
  }
}
</script>

<style scoped lang="scss">
.alert-bar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
</style>
