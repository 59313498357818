<template>
  <div id="app">
    <navigation />

    <transition name="fade">
      <alert-bar v-if="alertBar.show" />
    </transition>

    <main>
      <div id="wrapper">
        <router-view/>
      </div>
    </main>
  </div>
</template>

<script>
import Navigation from './components/layout/Navigation'
import AlertBar from './components/basic/AlertBar'
import { mapState } from 'vuex'

export default {
  name: 'app',
  components: {
    AlertBar,
    Navigation
  },
  computed: {
    ...mapState({
      alertBar: state => state.alertBar
    })
  }
}
</script>
