import axios from '../../config/axios'

const name = 'categories'

export default {
  namespaced: true,
  state: {
    items: []
  },
  mutations: {
    setItems (state, items) {
      state.items = items
    }
  },
  actions: {
    async fetch ({ commit, rootState }) {
      const response = await axios.get(name, {
        params: {
          accountBookId: rootState.accountBooks.current._id
        }
      })

      commit('setItems', response.data)
    },
    create ({ commit, dispatch }, transaction) {
      return axios.post(name, transaction).then(response => {
        dispatch('fetch')
      })
    }
  }
}
