<template>
  <nav class="navbar is-flex is-justify-content-space-between is-align-items-center pr-3" role="navigation" aria-label="main navigation">
    <div class="navbar-brand is-justify-content-space-between">
      <router-link to="/" class="navbar-item">
        <img src="/img/pig.svg" width="35" height="35">
        miflab
      </router-link>
    </div>

    <dropdown id="profileDropdown">
      <template slot="buttonContent">
        <span class="icon">
          <i class="fa fa-user" aria-hidden="true"></i>
        </span>
      </template>

      <template slot="dropdownContent">
        <router-link to="/edit-profile" class="dropdown-item">
          {{ $t('navigation.editProfile') }}
        </router-link>
        <a @click.prevent="logout" class="dropdown-item">
          {{ $t('common.logout') }}
        </a>
      </template>
    </dropdown>
  </nav>
</template>

<script>

import Dropdown from '../basic/Dropdown'
export default {
  name: 'navigation',
  components: { Dropdown },
  methods: {
    logout (event) {
      event.preventDefault()
      this.$store.dispatch('logout')
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/scss/variables';

  .navbar {
    background: none;
  }

  .navbar-brand {
    font-family: Pacifico, sans-serif;
    font-size: 1.5rem;

    .navbar-item, .navbar-item:focus, .navbar-item:hover {
      color: white;
    }
  }

  .navbar-menu {
    background: $primary;

    .navbar-item, .navbar-item:focus, .navbar-item:hover {
      color: white;
    }
  }

  a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
    background: none;
    color: inherit;
  }
</style>
